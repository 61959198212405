import React from "react";
import { Button } from "@mui/material";
import ReactStars from "react-rating-stars-component";
import { useQuery } from "@apollo/client";

import { USER_TYPE } from "@constants/user.constants";
import { LIST_REVIEW_CUSTOMER } from "../../../Graphql/queries";

export function CatererReviewSection({ catererId, catererName, userType, onAddReview }) {
  const { data: reviewData } = useQuery(LIST_REVIEW_CUSTOMER, {
    variables: { catererId, adminFlag: false },
  });

  function dateDiffInDays(date) {
    const currentDate = new Date() as any;
    const existingDate = new Date(date) as any;
    const diffInMs = Math.abs(currentDate - existingDate);
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    if (diffInDays === 0) {
      return "Today";
    } else if (diffInDays < 7) {
      return `${diffInDays} day(s) ago`;
    } else if (diffInDays >= 365) {
      let year = Math.floor(diffInDays / 365);
      return `${year} year(s) ago`;
    } else if (diffInDays >= 30) {
      let month = Math.floor(diffInDays / 30);
      return `${month} month(s) ago`;
    } else if (diffInDays >= 7) {
      let weeks = Math.floor(diffInDays / 7);
      return `${weeks} week(s) ago`;
    }
  }

  return (
    <div className="review cater-detail-box">
      <div className="review-title d-flex align-items-center justify-content-between">
        <h4 className="h4">Review for {catererName}</h4>
        {userType !== USER_TYPE.admin && userType !== USER_TYPE.caterer ? (
          <Button
            onClick={onAddReview}
            className="add-review-btn d-none d-md-block"
            variant="contained"
            color="primary"
          >
            Add a Review
          </Button>
        ) : null}
      </div>
      {reviewData?.listReviewsCaterer?.reviewsList?.map((item, idx) => (
        <div className="rating" key={`rating-${idx}`}>
          <div className="rating-star d-flex align-items-center">
            <ReactStars
              count={5}
              size={24}
              activeColor="#ffd700"
              value={item?.star}
              edit={false}
              classNames="review-star-item"
            />
            <span className="ms-3">{`${dateDiffInDays(item?.reviewDate)}`}</span>
          </div>
          <h6>{`${item?.customerFName}`}</h6>
          <p>{item?.comment}</p>
        </div>
      ))}
      {userType !== USER_TYPE.admin && userType !== USER_TYPE.caterer ? (
        <Button
          onClick={onAddReview}
          className="add-review-btn w-100 d-flex d-md-none"
          variant="contained"
          color="primary"
        >
          Add a Review
        </Button>
      ) : null}
    </div>
  );
}
