//this page is for showing all the customers
import React, { useState } from "react";
import SideMenu from "../../components/organisms/sidebar";
import LoggedHeader from "../Common/loggedHeader";
import { Table, Select, MenuItem, Menu} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatter } from '../Common/commonUtils';
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { LIST_USERS } from "../../Graphql/queries";
import { DELETE_CUSTOMER } from "../../Graphql/mutations";
import Pagination from "@mui/material/Pagination";
import DialogBox from "../Common/dialogBox";
import { toggleSnackbar,authVar } from "../../ReactiveVariables/index";
import Stack from "@mui/material/Stack";
import EditCustomerProfile from "./CustomerListDetails/editProfile";

export default function CustomerList() {
  const [search, setSearch] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [openProfile, setOpenProfile] = useState(false);
  const [page, setPage] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem,setSelectedItem] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };
  const options = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }
  const navigate = useNavigate()
  const customerVariables = {
    userType: "Customer",
    limit: rowsPerPage,
    skip: (page - 1) * rowsPerPage,
    search: search,
  };
  const { data, loading } = useQuery(LIST_USERS, {
    variables: customerVariables,
  });
  const [deleteCustomer]= useMutation(DELETE_CUSTOMER,{refetchQueries:['ListUser']})
  const handleChangePage = (e, pages) => {
    setPage(pages);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handlePageInputChange(event) {
    setRowsPerPage(Number(event.target.value));
    setPage(1);
  }
  //customer delete function
  function handleDelete(){
    try { deleteCustomer({ variables: {customerId: selectedItem?._id } })
        .then((res) => {
          toggleSnackbar({
            status: true,
            message: "Customer deleted successfully",
            variant: 'success'
          });
        })
        .catch((err) => {
          const message = err && err.message
          if(message==="Not authenticated"){
            localStorage.clear();
            authVar({
              loaded: true,
              auth: false,
            });
            navigate("/admin", { state: "noAuth" })
          }else{
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
        });
      } catch (err) {
        const message = err && err.message;
        toggleSnackbar({
          status: true,
          message: message,
          variant: "error",
        });
      }
  }
  return (
    <div>
      {openProfile && <EditCustomerProfile
      openProfile={openProfile}
      setOpenProfile={setOpenProfile}
      selectedItem={selectedItem}
      setSelectedItem= {setSelectedItem}
        />}
      {openDialog && (<DialogBox
        setOpenDialog={setOpenDialog}
        handleDelete={handleDelete}
        openDialog={openDialog}
        title="Delete"
        discription="Are you sure you want to delete this customer?"
      />
      )}
      <LoggedHeader title="Customers" user="admin" />
      <SideMenu selected="Customers" user="admin" />
      <div className="contentWrap">
        <div className="contentWrapInner">
          <div className="location-head">
            <div className="location-head-title justify-content-between">
           { data?.listUser?.count? <h5>{`${data?.listUser?.count} Customers`}</h5>:<p>Loading...</p>}
              <input
                placeholder="Search by Customer Name"
                className="form-control"
                value={search}
                onChange={(e) => {
                  setPage(1);
                  setSearch(e.target.value);
                }}
              />
            </div>
          </div>
          <TableContainer>
            <Table
              className="commonTabel"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Customer Name</TableCell>
                  <TableCell align="left">Email</TableCell>
                  <TableCell align="center">Sign up Date</TableCell>
                  <TableCell align="center">Phone</TableCell>
                  <TableCell align="center">Orders</TableCell>
                  <TableCell align="center">Total Spend</TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p> Loading...</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : data && data?.listUser?.data?.length > 0 ? (
                  data &&
                  data?.listUser?.data?.map((item) => (
                    <TableRow style={{ cursor: "pointer" }}>
                      <TableCell component="th" scope="row" onClick={() => navigate(`${item?._id}`)}>
                        {item?.name}
                      </TableCell>
                      <TableCell align="left" onClick={() => navigate(`${item?._id}`)}>{item?.email}</TableCell>
                      <TableCell align="center">{new Date(item?.createdDate).toLocaleDateString('en-US',options)}</TableCell>
                      <TableCell align="center">{item?.phoneNumber}</TableCell>
                      <TableCell align="center">{item?.orderCount}</TableCell>
                      <TableCell align="center">{formatter.format(item?.totalSpend)}</TableCell>
                      <TableCell align="center">
                        <img
                          style={{ cursor: "pointer" }}
                            onClick={(e) => { setSelectedItem(item); handleClick(e) }}
                          src="../assets/images/more-horizontal.svg"
                          alt="img"
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : search ? (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No data Found</p>
                      </div>
                    </TableCell>
                  </TableRow>
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <p>No list added Yet!</p>
                      </div>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <div className="table-pagenation">
          <div className="rowCount">
            <Select
              onChange={handlePageInputChange}
              value={rowsPerPage}
            >
              <MenuItem value={10}>{10}</MenuItem>
              <MenuItem value={25}>{25}</MenuItem>
              <MenuItem value={50}>{50}</MenuItem>
              <MenuItem value={100}>{100}</MenuItem>
            </Select>
          </div>

          <Stack spacing={2}>
            <Pagination
              className="tableCount"
              count={Math.ceil(data?.listUser?.count / rowsPerPage)}
              variant="outlined"
              shape="rounded"
              page={page}
              onChange={handleChangePage}
            />
          </Stack>
        </div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          className="menu-list-more"
        >
        <MenuItem onClick={() => { navigate(`${selectedItem?._id}`);handleClose() }}>View</MenuItem>
          <MenuItem
            onClick={() => {
              setOpenProfile(true);
              handleClose();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem onClick={() => { setOpenDialog(true); handleClose() }}>Delete</MenuItem>
        </Menu>
      </div>
    </div>
  );
}
