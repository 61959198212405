import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useQuery, useLazyQuery, useReactiveVar } from "@apollo/client";
import { Box, Modal, IconButton } from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

import { GET_ONE_USER, LIST_CUISINE_TYPE, LIST_CATERER } from "../../Graphql/queries";
import { userDeatils, authVar } from "../../ReactiveVariables/index";

import SideMenu from "@components/organisms/sidebar";
import LoggedHeader from "../../Modules/Common/loggedHeader";
import BankInfo from "../../Modules/Location/locationEdits/bankInfoEdit";
import CaterCashInfo from "../../Modules/Location/locationEdits/caterCashEdit";
import AdminProfileInfo from "../../Modules/Location/locationEdits/adminProfileEdit";
import BusinessDetails from "../../Modules/Location/locationEdits/businessDetailsEdit";
import HoursDetails from "../../Modules/Location/locationEdits/hoursEdit";
import FeeDetails from "../../Modules/Location/locationEdits/feesEdit";
import OthersDetails from "../../Modules/Location/locationEdits/othersEdit";
import OptionDetails from "../../Modules/Location/locationEdits/optionEdit";
import AddBusiness from "../../Modules/Location/addBusiness";
import VendorStatements from "../../Modules/Location/vendorStatements";

import { ManageAdmin } from "./components/manage-admin";
import { IGetOneCatererResponse } from "typings/caterer.api";
import { BankDetails } from "./components/bank-details";
import { CaterCash } from "./components/cater-cash";
import { DeliveryHours } from "./components/delivery-hours";
import { DeliveryFees } from "./components/delivery-fees";
import { CatererOptions } from "./components/caterer-options";
import { OtherOptions } from "./components/other-options";
import { BusinessDetailsOptions } from "./components/business-details-options";
import { getUserCatererId } from "@utils/user-format.utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 4,
};

export default function LocationDetails(props) {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const locationDetails = location?.state;
  const user = useReactiveVar(userDeatils);

  const userType =
    user?.data?.login?.userType ||
    user?.data?.currentUserDetails?.userType ||
    user?.data?.createUserIdentity?.userType ||
    user?.data?.createCaterer?.userType;

  console.log(userType, props.userType);

  // TODO: use only this object later
  const userId = getUserCatererId(user);

  const userData = user?.data?.currentUserDetails || user?.data?.login || user?.data?.createCaterer;

  const userLog =
    user?.data?.login?.userSrc ||
    user?.data?.createCaterer?.userSrc ||
    user?.data?.currentUserDetails?.userSrc;

  const [openBank, setOpenBank] = useState(false);
  const [openCaterCash, setCaterCash] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);
  const [openBusiness, setOpenBusiness] = useState(false);
  const [openHours, setOpenHours] = useState(false);
  const [openFees, setOpenFees] = useState(false);
  const [openReadMore, setOpenReadMore] = useState(false);
  const [selectedHeading, setSelectedHeading] = useState(null);
  const [selectedContent, setSelectedContent] = useState(null);
  const [openOthers, setOpenOthers] = useState(false);
  const [openOptions, setOpenOptions] = useState(false);
  const [openNewBusiness, setOpenNewBusiness] = useState(false);
  const [getLocId, setGetLocId] = useState("");

  const catererVariables = {
    skip: 0,
    limit: 50,
    userId:
      user?.data?.currentUserDetails?._id ||
      user?.data?.login?._id ||
      user?.data?.createCaterer?._id,
  };

  const [listCaterer, { data: catererData }] = useLazyQuery(LIST_CATERER);
  const catererId = getLocId ? getLocId : props?.userType === "Admin" ? id : userId;

  const { data, error } = useQuery<IGetOneCatererResponse>(GET_ONE_USER, {
    variables: {
      getOneCatererId: catererId,
      agsType: "id",
    },
    skip: !catererId,
    fetchPolicy: "network-only",
  });

  const { data: cuisineTypes } = useQuery(LIST_CUISINE_TYPE, {
    variables: { sortField: "cuisineName" },
  });

  useEffect(() => {
    if (error && error.message === "Not authenticated") {
      localStorage.clear();
      authVar({
        loaded: true,
        auth: false,
      } as any);

      const redirectPath = userType === "Admin" ? "/admin" : "/login";
      navigate(redirectPath, { state: "noAuth" });
    }
  }, [error]);

  useEffect(() => {
    listCaterer({ variables: catererVariables });
  }, [locationDetails]);

  function onReadMore() {
    setOpenReadMore(true);
    setSelectedHeading(
      "Any special delivery instructions or any other information to share with the customer?",
    );
    setSelectedContent(data?.getOneCaterer?.specialDeliveryInstn);
  }

  return (
    <div>
      {/* header for switching the Locations */}
      {props.userType !== "Admin" && (
        <LoggedHeader
          locationDetails={locationDetails}
          location={catererData}
          openNewBusiness={openNewBusiness}
          setOpenNewBusiness={setOpenNewBusiness}
          setGetLocId={setGetLocId}
          title="Profile"
        />
      )}
      {props.userType !== "Admin" && <SideMenu selected="Profile" />}
      <div className={props.userType !== "Admin" ? "contentWrap" : ""}>
        {openNewBusiness && (
          <AddBusiness openNewBusiness={openNewBusiness} setOpenNewBusiness={setOpenNewBusiness} />
        )}
        {openBank && (
          <BankInfo openBank={openBank} setOpenBank={setOpenBank} data={data} userData={userData} />
        )}
        {openCaterCash && (
          <CaterCashInfo
            openCaterCash={openCaterCash}
            setCaterCash={setCaterCash}
            data={data}
            userData={userData}
          />
        )}
        {openProfile && (
          <AdminProfileInfo
            openProfile={openProfile}
            setOpenProfile={setOpenProfile}
            userData={userData}
            data={data}
            userType={props.userType}
            catererimageEdit
          />
        )}
        {openBusiness && (
          <BusinessDetails
            openBusiness={openBusiness}
            setOpenBusiness={setOpenBusiness}
            data={data}
            userData={userData}
            cusineTypes={cuisineTypes}
          />
        )}
        {openHours && (
          <HoursDetails
            openHours={openHours}
            setOpenHours={setOpenHours}
            data={data}
            userData={userData}
            userType={props.userType}
          />
        )}
        {openFees && (
          <FeeDetails
            openHours={openFees}
            setOpenFees={setOpenFees}
            data={data}
            userData={userData}
            userType={props.userType}
          />
        )}
        {openOthers && (
          <OthersDetails
            openOthers={openOthers}
            setOpenOthers={setOpenOthers}
            data={data}
            userData={userData}
          />
        )}
        {openOptions && (
          <OptionDetails
            openOptions={openOptions}
            setOpenOptions={setOpenOptions}
            data={data}
            userData={userData}
          />
        )}

        {/* Modal for read more */}
        <Modal
          open={openReadMore}
          onClose={() => {
            setOpenReadMore(false);
            setSelectedContent(null);
            setSelectedHeading(null);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          className="ld-readmore-wrap">
          <Box sx={style} className="ld-readmore-box">
            <div className="d-flex align-items-center justify-content-between">
              <h5 className="h5">{selectedHeading}</h5>
              <IconButton
                onClick={() => {
                  setOpenReadMore(false);
                  setSelectedContent(null);
                  setSelectedHeading(null);
                }}>
                <CloseIcon />
              </IconButton>
            </div>
            <p>{selectedContent}</p>
          </Box>
        </Modal>

        <div className="contentWrapInner">
          <div className="row">
            <div className="col-md-6 ld-tile-outer d-flex flex-column">
              <BusinessDetailsOptions
                businessName={data?.getOneCaterer?.businessName}
                imageUrl={data?.getOneCaterer?.imageUrl}
                caterCash={data?.getOneCaterer?.caterCash}
                createdDate={data?.getOneCaterer?.createdDate}
                phone={data?.getOneCaterer.businessPhone}
                isAdmin={userType === "Admin"}
                additionalPhone={data?.getOneCaterer.additionalPhone}
                deliveryEmails={data?.getOneCaterer?.deliveryEmail || []}
                tagLine={data?.getOneCaterer?.tagLine}
                locationName={data?.getOneCaterer?.locationName}
                zipCode={data?.getOneCaterer?.catererZipCode}
                deliveryInstructions={data?.getOneCaterer?.specialDeliveryInstn || ""}
                allCuisines={cuisineTypes?.listCuisineType?.data || []}
                cuisines={data?.getOneCaterer?.cuisineType || []}
                onReadMore={onReadMore}
                setOpenBusiness={setOpenBusiness}
              />
            </div>

            <div className="col-md-6 ld-tile-outer d-flex flex-column">
              <ManageAdmin
                isAdmin={props.userType === "Admin"}
                userLog={userLog}
                name={data?.getOneCaterer?.catererUsers[0]?.userDtls?.name}
                phone={data?.getOneCaterer?.catererUsers[0]?.userDtls?.phoneNumber}
                email={data?.getOneCaterer?.catererUsers[0]?.userDtls?.email}
                isSMSEnabled={data?.getOneCaterer?.smsEnabled}
                setOpenProfile={setOpenProfile}
              />

              <BankDetails
                accountName={data?.getOneCaterer?.accountName}
                bankName={data?.getOneCaterer?.bankName}
                setOpenBank={setOpenBank}
              />
              <CaterCash
                isAdmin={props.userType === "Admin"}
                caterCash={data?.getOneCaterer?.caterCash}
                commission={data?.getOneCaterer?.commission}
                setCaterCash={setCaterCash}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 ld-tile-outer d-flex flex-column">
              <DeliveryHours
                status={data?.getOneCaterer?.status}
                deliveryHours={data?.getOneCaterer?.deliveryHrs}
                setOpenHours={setOpenHours}
              />
            </div>
            <div className="col-md-6 ld-tile-outer d-flex flex-column">
              <DeliveryFees
                deliveryFeeTiers={data?.getOneCaterer?.deliveryFeeTiers}
                setOpenFees={setOpenFees}
                deliveryFee={data?.getOneCaterer?.deliveryFee || 0}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6 ld-tile-outer d-flex flex-column">
              <CatererOptions
                setOpenOptions={setOpenOptions}
                activeOptions={data?.getOneCaterer?.option || []}
              />

              <OtherOptions
                setOpenOthers={setOpenOthers}
                minOrderAmount={data?.getOneCaterer?.minOrderAmt || 0}
                deliveryFeeType={data?.getOneCaterer?.deliveryFeeType}
                deliveryFee={data?.getOneCaterer?.deliveryFee || 0}
                deliveryRadius={data?.getOneCaterer?.deliveryRadius}
                driverTipFlag={data?.getOneCaterer?.driverTipFlag}
                driverTipType={data?.getOneCaterer?.driverTipType}
                driverTip={data?.getOneCaterer?.driverTip}
                preparationTime={data?.getOneCaterer?.preprationTime}
                isTablewareCharged={data?.getOneCaterer?.tablewareCharged}
                tablewarePrice={data?.getOneCaterer?.tablewareAmount}
                hasDrivers={data?.getOneCaterer?.hasDrivers}
                customReviewCount={data?.getOneCaterer?.customReviewCount}
              />
            </div>

            <div className="col-md-6">
              <VendorStatements catererId={catererId} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
