// this drawer open for editing the other options
import React from 'react';

import { useEffect } from 'react';
import Drawer from "@mui/material/Drawer";
import {
  Button,
  FormControl,
  InputLabel,
  Select,
  InputAdornment,
  MenuItem,
  CircularProgress
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  toggleSnackbar,authVar,userDeatils
} from "../../../ReactiveVariables/index";
import { useReactiveVar } from "@apollo/client";
import { useForm, Controller } from "react-hook-form";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormGroup from "@mui/material/FormGroup";
import { useMutation } from "@apollo/client/react/hooks";
import { UPDATE_CATERE } from "../../../Graphql/mutations";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { postiveValueConverter } from'../../Common/commonUtils';
import { hasDirectives } from '@apollo/client/utilities';

const StyledCheckbox = styled(Checkbox)`
  svg {
    color: #fd4e3b;
    font-size: 22px;
  }
`;

const customDelivery = [
  {
    from: "",
    to: "",
    minimumOrder: "",
    deliveryFee: "",
  },
];

export default function OthersDetails(props) {
  const navigate= useNavigate()
  const user = useReactiveVar(userDeatils);
  const userType =
  user?.data?.login?.userType || user?.data?.currentUserDetails?.userType || user?.data?.createUserIdentity?.userType || user?.data?.createCaterer?.userType;
  const currentData = props?.data?.getOneCaterer;
  const [driverTip, setDriverTip] = useState(
    currentData?.driverTipFlag ?? "non-mandatory"
  );
  const [tablewareCharged, setTablewareCharged] = useState(
    currentData?.tablewareCharged ?? false
  );
  const [hasDrivers, setHasDrivers] = useState(
    currentData?.hasDrivers ?? false
  );
  const [errorMsg,setErrorMsg] = useState("");

  const schema = yup.object().shape({
    deliveryRadius: yup.number().min(1).typeError('Delivery Radius must be minimum 1'),
    preprationTime: yup.string().required("Minimum preparation time is required")
    .test('isValidNumber', 'Please enter the valid time', function (value) {
      const regex = /^(?!0(\.0|\.00)?$)(\d*[0-9]\d*)?(\.25|\.50|\.5|\.75|\d+\.0|\d+\.00)?$/;
      return regex.test(value);
    }),
    tablewareAmount: yup.number().when('tablewareCharged', (_, schema) => {
      if (tablewareCharged) {
        return schema.transform(value => (isNaN(value) ? undefined : value))
          .required('Tableware price is required')
          .positive('Tableware price per person must be greater than 0')
      }

      return schema.transform(value => (isNaN(value) ? undefined : value)).notRequired();
    })
  });

  const {
    control,
    register,
    handleSubmit, formState: { errors },
    setValue: newSetValue,
    clearErrors
  } = useForm({
    mode: 'all',
    resolver: yupResolver(schema),
    defaultValues: {
      deliveryRadius: currentData?.deliveryRadius ?? 1,
      deliveryFeeType: currentData?.deliveryFeeType ?? "Amount",
      convenienceFeeType: currentData?.convenienceFeeType ?? "Percentage",
      driverTipType: currentData?.driverTipType ?? "Percentage",
      tablewareCharged: currentData?.tablewareCharged ?? false,
      tablewareAmount: currentData?.tablewareAmount ?? "0",
      hasDrivers: currentData?.hasDrivers ?? false,
      customReviewCount: currentData?.customReviewCount ?? 0,
    },
  });

  const [updateCaterer, { loading }] = useMutation(UPDATE_CATERE, { refetchQueries:['GetOneCaterer'],
    onCompleted: (res) => {
      props.setOpenOthers(false);
    },
  });

  // for allowing only positive values
  const handleInputChangeTip = (event) => {
    setErrorMsg("")
    event.target.value = postiveValueConverter(event, 'withDecimal');
  };

  // for allowing only positive values
  const handleInputChange = (event) => {
    event.target.value = postiveValueConverter(event, 'withDecimal');
  };
  const handleInputChangeWithoutDecimal = (event) => {
    event.target.value = postiveValueConverter(event, 'withoutDecimal');
  };

  // function for calling the update caterer mutation
  function onSubmit(params) {
    if (!params.driverTip&&driverTip==="mandatory") {
      setErrorMsg("Driver tip is required")
      return;
    }

    const { tablewareAmount, ...paramsRest } = params;

    const payload = {
      ...paramsRest,
      minOrderAmt: isNaN(parseFloat(params["minOrderAmt"])) ? 0 : parseFloat(params["minOrderAmt"]),
      driverTipFlag: driverTip,
      driverTip: isNaN(parseFloat(params["driverTip"])) ? 0 : parseFloat(params["driverTip"]),
      deliveryFee: isNaN(parseFloat(params["deliveryFee"])) ? 0 : parseFloat(params["deliveryFee"]),
      tablewareCharged,
      ...(tablewareCharged && {
        tablewareAmount: params.tablewareAmount
      }),
      hasDrivers,
      customReviewCount: isNaN(parseFloat(params["customReviewCount"])) ? 0 : parseFloat(params["customReviewCount"]),
    };

    console.log("payload", payload);

    try {
      updateCaterer({
        variables: {
          id: props?.userData?._id,
          catererId: props?.data?.getOneCaterer?.catererUsers[0]?.catererId || props?.userData?.catererId,
          data: payload
        },
      }).then(() => {
        toggleSnackbar({
          status: true,
          message: "Other details updated successfully",
          variant: "success",
        });
      })
      .catch((err) => {
        const message = err && err.message;

        if (message==="Not authenticated"){
          localStorage.clear();

          authVar({
            loaded: true,
            auth: false,
          });

          userType==="Admin"? navigate("/admin", { state: "noAuth" }): navigate("/login", { state: "noAuth" })
        } else {
          toggleSnackbar({
            status: true,
            message: message,
            variant: "error",
          });
        }
      });
    } catch (err) {
      const message = err && err.message;
      toggleSnackbar({
        status: true,
        message: message,
        variant: "error",
      });
    }
  }

  return (

    <Drawer className='common-drawer'
      anchor={"right"}
      open={props.openOthers}
      onClose={() => props.setOpenOthers(false)}
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <div className='drawer-head'>
          <h4 className="h4"><img src="../assets/images/icon-other.svg" className="img-fluid" alt="Contact Information Icon" />Others</h4><span style={{cursor:'pointer'}} onClick={()=>props.setOpenOthers(false)}><img src="../assets/images/x-icon.svg" className="img-fluid" alt="close icon" /></span>
        </div>
        <div className='create-step-form'>
          <div className='container-fluid'>
            <div className="row addBusinessSet mt-0">
              <h6 className="h6">Minimum Order/Delivery Radius</h6>
              <div className="col-md-6">
                <TextField
                  id="outlined-basic"
                  label="MINIMUM ORDER AMOUNT"
                  defaultValue={
                    currentData?.minOrderAmt === 0
                      ? ""
                      : currentData?.minOrderAmt
                  }
                  variant="outlined"
                  className="textField allFeild"
                  {...register("minOrderAmt")}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div className="col-md-6 textBox">
              <Controller
                  control={control}
                  name="deliveryRadius"
                  render={({ field: { onChange, value } }) => (
                      <TextField
                          id="outlined-basic"
                          label="Delivery radius"
                          variant="outlined"
                          className="textField allFeild"
                          {...register("deliveryRadius")}
                          onChange={handleInputChangeWithoutDecimal}
                      />
                )}
                />
                {errors?.deliveryRadius &&
                    <span className='error-msg'> {errors?.deliveryRadius?.message}</span>
                }
              </div>

              <h6 className="h6">Delivery Fee</h6>
              <div className="col-md-4">
                <Controller
                  control={control}
                  name="deliveryFeeType"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      label="DELIVERY FEE"
                       variant="outlined"
                      defaultValue={
                        currentData?.deliveryFee === 0
                          ? ""
                          : currentData?.deliveryFee
                      }
                      className="textField allFeild"
                      {...register("deliveryFee")}
                      onChange={handleInputChange}
                      InputProps={{
                        endAdornment: value === "Percentage" && (
                          <InputAdornment position="end">%</InputAdornment>
                        ),
                        startAdornment: value === "Amount" && (
                          <InputAdornment position="start">$</InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </div>
              <div className="col-md-4">
                <FormControl fullWidth className="textField allFeild">
                  <InputLabel id="demo-simple-select-label">Type</InputLabel>
                  <Controller
                    control={control}
                    name="deliveryFeeType"
                    render={({ field: { onChange, value } }) => (
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={value}
                        label="Type"
                        onChange={(e) => {
                          onChange(e.target.value);
                        }}
                      >
                        <MenuItem value={"Percentage"}>Percentage</MenuItem>
                        <MenuItem value={"Amount"}>Amount</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </div>
            </div>

            <div className="row addBusinessSet mt-0">
            </div>
            <div>
              <hr />
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Driver Tip</h6>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={
                            driverTip === "non-mandatory" ? true : false
                          }
                          onChange={() => setDriverTip("non-mandatory")}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label="Non-mandatory"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={driverTip === "mandatory" ? true : false}
                          onChange={() => setDriverTip("mandatory")}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label="Mandatory"
                    />
                  </FormGroup>
                </div>
              </div>
              {driverTip === "mandatory" && (
                <div className="col-md-4 textBox">
                  <Controller
                    control={control}
                    name="driverTipType"
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        id="outlined-basic"
                        label="DRIVER TIP"
                        defaultValue={
                          currentData?.driverTip === 0
                            ? ""
                            : currentData?.driverTip
                        }
                        variant="outlined"
                        className="textField allFeild"
                        {...register("driverTip")}
                        onChange={handleInputChangeTip}
                        InputProps={{
                          endAdornment: value === "Percentage" && (
                            <InputAdornment position="end">%</InputAdornment>
                          ),
                          startAdornment: value === "Amount" && (
                            <InputAdornment position="start">
                              $
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  />
                  {errorMsg && (
                  <span className="error-msg">{errorMsg}</span>
                )}
                </div>
              )}
              {driverTip === "mandatory" && (
                <div className="col-4">
                  <FormControl fullWidth className="textField allFeild">
                    <InputLabel id="demo-simple-select-label">
                      Type
                    </InputLabel>
                    <Controller
                      control={control}
                      name="driverTipType"
                      render={({ field: { onChange, value } }) => (
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={value}
                          label="Age"
                          onChange={(e) => {
                            onChange(e.target.value);
                          }}
                        >
                          <MenuItem value={"Percentage"}>Percentage</MenuItem>
                          <MenuItem value={"Amount"}>Amount</MenuItem>
                        </Select>
                      )}
                    />
                  </FormControl>
                </div>
              )}
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Preparation Time</h6>
              <div className="col-md-12 textBox">
                <Controller
                    control={control}
                    name="preprationTime"
                    render={({ field: { onChange, value } }) => (
                  <TextField
                    id="outlined-basic"
                    defaultValue={
                      currentData?.preprationTime === "0"
                        ? ""
                        : currentData?.preprationTime
                    }
                    label="MINIMUM PREPARATION TIME REQUIRED*"
                    variant="outlined"
                    className="textField allFeild"
                    {...register("preprationTime", {
                      onChange: handleInputChange,
                    })}

                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">HRS</InputAdornment>
                      ),
                    }}
                  />
                  )}/>
                {errors?.preprationTime && (
                  <span className="error-msg" >{errors?.preprationTime?.message}</span>
                )}
                <p className="error-msg" style={{ color: "#9F9E9E",right: "12px" }}>
                  1.5 hours = 90 minutes
                </p>
              </div>
            </div>
            <div>
              <hr />
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Additional Charge for Tableware</h6>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={tablewareCharged}
                          onChange={() => setTablewareCharged(true)}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label="Yes"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={!tablewareCharged}
                          onChange={() => setTablewareCharged(false)}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label="No"
                    />
                  </FormGroup>
                </div>
              </div>
              { tablewareCharged &&
                <div className="row">
                  <div className="col-md-12 textBox">
                    <Controller
                      control={control}
                      name="tablewareAmount"
                      render={({ field: { onChange, value } }) => (
                        <TextField
                          id="outlined-basic"
                          label="PRICE"
                          defaultValue={currentData?.tablewareAmount}
                          value={value}
                          variant="outlined"
                          className="textField allFeild"
                          {...register("tablewareAmount")}
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            endAdornment: <InputAdornment position="end">Per Person</InputAdornment>
                          }}
                        />
                      )}
                    />
                    {errors.tablewareAmount && (
                      <span className="error-msg">{errors.tablewareAmount.message}</span>
                    )}
                  </div>
                </div>
              }
            </div>
            <div className="row addBusinessSet">
              <h6 className="h6">Has drivers?</h6>
              <div className="row">
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={hasDrivers}
                          onChange={() => setHasDrivers(true)}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label="Yes"
                    />
                  </FormGroup>
                </div>
                <div className="col-md-4">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <StyledCheckbox
                          checked={!hasDrivers}
                          onChange={() => setHasDrivers(false)}
                          color={"primary"}
                          icon={
                            <CheckCircleOutlineIcon
                              style={{ color: "#9F9E9E" }}
                            />
                          }
                          checkedIcon={<CheckCircleIcon />}
                        />
                      }
                      label="No"
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="row addBusinessSet">
              <h6 className="h6">Custom Review Count</h6>
              <div className="col-md-12 textBox">
                <Controller
                  control={control}
                  name="customReviewCount"
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      id="outlined-basic"
                      label="CUSTOM REVIEW COUNT"
                      defaultValue={
                        currentData?.customReviewCount === 0
                          ? ""
                          : currentData?.customReviewCount
                      }
                      variant="outlined"
                      className="textField allFeild"
                      {...register("customReviewCount")}
                      onChange={handleInputChangeWithoutDecimal}
                    />
                  )}/>
              </div>
            </div>
          </div>

        </div>

        <div className="createStepBtn">
          <Button
            className="cancelBtn"
            variant="contained"
            color="secondary"
            onClick={() => props.setOpenOthers(false)}
          >
            Cancel
          </Button>
          <Button
            className="saveBtn"
            variant="contained"
            type="submit"
            color="primary"
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              "Update"
            )}{" "}
          </Button>
        </div>
      </form>
    </Drawer>
  );
}
