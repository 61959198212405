import {
  CATERER_DRIVER_TIP_TYPE,
  DELIVERY_FEE_TYPE,
  ORDER_TIP_TYPE,
} from "@constants/order.constants";
import { formatter } from "Modules/Common/commonUtils";
import React from "react";

interface IOtherOptionsProps {
  setOpenOthers(v: boolean);
  minOrderAmount: number;
  deliveryFeeType: DELIVERY_FEE_TYPE;
  deliveryFee: number;
  deliveryRadius: null | string;
  driverTipFlag: CATERER_DRIVER_TIP_TYPE;
  driverTipType: ORDER_TIP_TYPE;
  driverTip: number;
  preparationTime: string;
  isTablewareCharged: boolean;
  tablewarePrice: number;
  hasDrivers?: boolean;
  customReviewCount?: number;
}

export function OtherOptions({
  minOrderAmount,
  deliveryFeeType,
  deliveryFee,
  deliveryRadius,
  driverTip,
  driverTipType,
  driverTipFlag,
  preparationTime,
  isTablewareCharged,
  tablewarePrice,
  hasDrivers,
  customReviewCount,
  setOpenOthers,
}: IOtherOptionsProps) {
  return (
    <div className="location-details-tile h-100">
      <div className="ld-titles">
        <h4 className="h4">
          <img src="../assets/images/icon-other.svg" className="img-fluid" alt="Other Icon" />
          Other
        </h4>
        <a className="ld-edit" onClick={() => setOpenOthers(true)}>
          <img src="../assets/images/icon-edit.svg" className="img-fluid" alt="Edit Icon" />
          Edit
        </a>
      </div>
      <div className="ld-others">
        <ul>
          <li>
            <span>Minimum Order Amount</span>
            <b>{formatter.format(minOrderAmount)}</b>
          </li>
          <li>
            <span>Delivery Fee</span>

            <b>
              {deliveryFeeType === "Percentage" ? `${deliveryFee}%` : formatter.format(deliveryFee)}
            </b>
          </li>
          <li>
            <span>Delivery Radius</span>
            <b>{deliveryRadius || 1} MI</b>
          </li>
          <li>
            <span>Driver Tip</span>
            {driverTipFlag === CATERER_DRIVER_TIP_TYPE.default ? (
              driverTipFlag
            ) : (
              <b>
                {driverTipType === ORDER_TIP_TYPE.percentage
                  ? `${driverTip || 0}%`
                  : `$${driverTip || 0}`}
              </b>
            )}
          </li>
          <li>
            <span>Minimum Preparation Time Required</span>
            <b>{preparationTime || 0} HRS</b>
          </li>
          <li>
            <span>Additional Charge for Tableware</span>
            <b>{!isTablewareCharged ? "No" : `${tablewarePrice} / Per Person`}</b>
          </li>
          <li>
            <span>Has drivers?</span>
            <b>{hasDrivers !== undefined && hasDrivers ? "Yes" : "No"}</b>
          </li>
          <li>
            <span>Custom review count</span>
            <b>{customReviewCount ? customReviewCount : 0}</b>
          </li>
        </ul>
      </div>
    </div>
  );
}
